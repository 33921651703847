import * as React from "react";
import { useState } from "react";
import { useSpring, animated } from "react-spring";

export interface ICounterProps {
    description?: string;
    iconClassName?: string;
    countUpToNum?: any;
    isVisible?: boolean;
}

export const Counter = (props: ICounterProps) => {
    const [open, toggle] = useState(false);

    const [springProps, setSpringProps] = useSpring(() => ({
        number: 0,
        from: { number: 0 }
    })) as any;

    if (props.isVisible) {
        setSpringProps({
            number: props.countUpToNum,
            from: { number: 0 }
        });
    }

    let fontSize = props.description && props.description!.length > 30 ? "30px" : "40px"
    //Media query hack, presuming people on smaller screens will render on smaller screens, does not work if resizing, can add event listeners if need be?? but that would be a stretch
    window.innerWidth < 1000 ? fontSize = "25px" : null
    return (
        <div className="count-container h-100">
            <div className="count-col h-100">
                <i
                    className={`count-icon ${
                        props.iconClassName ? props.iconClassName : "fas fa-coffee"
                    } fa-2x`}
                />
                <p style={{fontSize: fontSize, padding: "0"}} className="count-des">{props.description}</p>
                <animated.div className="count-number" style={{padding: "0"}}>
                    {springProps.number.interpolate((x: any) => x.toFixed(0))}
                </animated.div>
            </div>
        </div>
    );
};
