import * as React from "react";
import * as ReactDOM from "react-dom";
import "./styles/countUp.scss";
import TrackVisibility from "react-on-screen";
import { Counter, ICounterProps } from "./components/countUp";

class App extends React.Component<ICounterProps, any> {
  render() {
    return (
      <TrackVisibility>
        {({ isVisible }) => (
          <Counter
            description={this.props.description}
            iconClassName={this.props.iconClassName}
            countUpToNum={this.props.countUpToNum}
            isVisible={isVisible}
          />
        )}
      </TrackVisibility>
    );
  }
}

export function MountCountUp(props: ICounterProps, el: Element) {
  ReactDOM.render(<App {...props} />, el);
}
